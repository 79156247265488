import { FixedTextService } from './../../services/api/fixed-text.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.page.html',
  styleUrls: ['./text.page.scss'],
})
export class TextPage implements OnInit {

  public modalTitle: string;
  public text: string;

  constructor(
    private modalController: ModalController,
    private fixedTextService: FixedTextService
  ) { }

  ngOnInit() {
    const id  = localStorage.getItem('textId');
    this.fixedTextService.get(id).subscribe((data) =>{
      this.modalTitle = data.title;
      this.text = data.describer;
    });
  }

  async closeModal() {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const onClosedData: string = 'Wrapped Up';
    await this.modalController.dismiss(onClosedData);
  }

}
