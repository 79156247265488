import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastService } from './../toast.service';
import { ISubscriberFile } from './../../models/ISubscriber.models';
import { IHistoric } from './../../models/IHistoric.model';
import { environment } from './../../../environments/environment';
import { IFile } from './../../models/IFiles.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IFileShow } from 'src/app/models/IFileShow.models';

@Injectable({
  providedIn: 'root',
})
export class FilesService {

  header: HttpHeaders;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private router: Router,
    private alertController: AlertController
  ) {
    this.initBearerToken();
  }

  get(id: string, search: string, page?: number): Observable<IFile> {
    this.initBearerToken();
    const api =  JSON.parse(localStorage.getItem(id));
    const url = `${environment.apiURL}/${api.url}?search=${search}&page=${page}`;

    return this.http.get<IFile>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  show(ref: any, id: number): Observable<any> {
    const url = `${environment.apiURL}/${ref}/${id}`;

    return this.http.get<IFile>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  wait(search: string): Observable<IFile> {
    const url = `${environment.apiURL}/subscriber/wait/me?search=${search}`;

    return this.http.get<IFile>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  historic(id: string): Observable<IHistoric> {
    const url = `${environment.apiURL}/file/historic/${id}`;

    return this.http.get<IFile>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  send(formData: FormData){
    const url = `${environment.apiURL}/file`;

    return this.http.post<IFile>(url,formData,  { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  subscriber(formData: FormData, fileId: number){
    const url = `${environment.apiURL}/subscriber/multiple/${fileId}`;

    return this.http.post<any>(url, formData,  { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  getSubscribe(id: number){
    const url = `${environment.apiURL}/subscriber/get/for/file/${id}`;

    return this.http.get<any>(url,{ headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  action(subscriber: ISubscriberFile, type: string){
    const url = `${environment.apiURL}/subscriber/${type}/${subscriber.id}/${subscriber.hash}`;
    return this.http.get<any>(url).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  cancelOwner(file: IFileShow){
    const url = `${environment.apiURL}/file/cancel/${file.id}`;

    return this.http.get<any>(url,{ headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  categories(search: string){
    const url = `${environment.apiURL}/category?search=${search}`;

    return this.http.get<any>(url,{ headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  lastContacts(search: string){
    const url = `${environment.apiURL}/subscriber/last/contact?search=${search}`;

    return this.http.get<any>(url,{ headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  sendCategories(name: string){
    const url = `${environment.apiURL}/category`;

    return this.http.post<any>(url, { name }, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  resendMailNotification(file: IFileShow){
    const url = `${environment.apiURL}/file/resend/notification/${file.id}`;
    return this.http.get<any>(url,  { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  async presentAlertSaleNewPlan() {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: 'Licença de uso expirada, para continuar compre uma nova licença',
      buttons: [
        {
          text: 'Comprar uma nova licença',
          handler: () => {
            this.router.navigateByUrl('license');
          }
        }
      ]
    });

    await alert.present();
  }

  async errorToast(res: any) {

    if (res.status === 401) {
      this.toastService.warning(res.error[0]);
      this.router.navigateByUrl('login');
    }else if (res.status === 402) {
      this.presentAlertSaleNewPlan();
    }else if (res.status === 403) {
      this.toastService.error(res.error[0]);
      this.router.navigateByUrl('login');
    }else if(res.status === 406){
      this.toastService.error(res.error);
    } else if(res.status === 415) {
      this.toastService.error(res.error[0]);
    } else {
      this.toastService.error('Não conseguimos conexão, tente novamente!');
    }

    return res;
  }

  private initBearerToken(){
    this.header = new HttpHeaders({
      authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }

}
