import { AlertController } from '@ionic/angular';
import { IMercadoPago } from './../../models/IMercadoPago.models';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment.prod';
import { Observable } from 'rxjs';
import { IPlan } from './../../models/IPlan.model';
import { Router } from '@angular/router';
import { ToastService } from './../toast.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  header: HttpHeaders;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    public alertController: AlertController,
    private router: Router
  ) {
    this.initBearerToken();
  }

  get(): Observable<IPlan> {
    this.initBearerToken();
    const url = `${environment.apiURL}/plan/list`;

    return this.http.get<IPlan>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  purchase(id: number): Observable<IMercadoPago>{
    const url = `${environment.apiURL}/purchase/${id}`;
    return this.http.post<IMercadoPago>(url, {}, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  async presentAlertSaleNewPlan() {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: 'Licença de uso expirada, para continuar acessando compre uma nova licença',
      buttons: [
        {
          text: 'Comprar uma nova licença',
          handler: () => {
            this.router.navigateByUrl('license');
          }
        }
      ]
    });

    await alert.present();
  }

  async errorToast(res: any) {

    if (res.status === 401) {
      this.toastService.warning(res.error[0]);
      this.router.navigateByUrl('login');
    }else if (res.status === 415) {
      this.toastService.error(res.error[0]);
    }else if (res.status === 403) {
      this.toastService.error(res.error[0]);
      this.router.navigateByUrl('login');
    } else {
      this.toastService.error('Não conseguimos conexão, tente novamente!');
    }

    return res;
  }

  private initBearerToken(){
    this.header = new HttpHeaders({
      authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }
}
