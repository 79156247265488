import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FixedTextService {

  public idPages = {
   'terms-use'     : 1,
   'legal-validity': 2,
    about          : 3,
    'access-plan'  : 4
  };

  public descPages = {
    1: 'Termos de Uso',
    2: 'Validade Jurídica',
    3: 'Sobre',
    4: 'Plano de Acesso',
   };

  constructor(
    private http: HttpClient,
    public toastController: ToastController
  ) {}


  get(id: string): Observable<any> {
    const api =  JSON.parse(localStorage.getItem(id));

    const url = `${environment.apiURL}/fixed/text/show/${id}`;
    const header = new HttpHeaders({
      authorization: `Bearer ${localStorage.getItem('token')}`,
    });

    return this.http.get<any>(url, { headers: header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }


  async errorToast(error: any) {
    console.log(error);
  }
}
