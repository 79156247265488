import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.page.html',
  styleUrls: ['./preview.page.scss'],
})

export class PreviewPage implements OnInit {

  public pdfSrc = '';
	public title = '';

  public constructor(private modalController: ModalController)
	{
	}

  ngOnInit(): void {
    const $img = document.querySelector('#file') as any;

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };
      reader.readAsArrayBuffer($img.files[0]);
    }
  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }

  async cancel() {
    const $img = document.querySelector('#file') as any;
    $img.value = '';
    this.closeModal();
  }
}
