/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { element } from 'protractor';
import { IUser } from './../../models/IHistoric.model';
import { environment } from './../../../environments/environment.prod';
import { IToken } from './../../models/IToken.models';
import { catchError, map } from 'rxjs/operators';
import { ToastService } from './../toast.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { IMe } from 'src/app/models/IMe.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private header: HttpHeaders;
  private email: string;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    public alertController: AlertController
  ) {
    this.initBearerToken();
  }

  login(email: string, password: string): Observable<any> {
    this.email = email;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const device_token = localStorage.getItem('device_token');
    const url = `${environment.apiURL}/login`;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.post<IToken>(url, { email, password, device_token }).pipe(
      map((result) => result),
      catchError((result) => this.checkStatus(result))
    );
  }

  checkStatus(result: any) {
    if (result.status === 401) {
      this.presentAlertConfirm();
    } else {
      this.toastService.error(result.error.error);
    }

    return result;
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Obrigado por inscrever-se!',
      message: 'Antes de começar é necessário validar seu endereço de e-mail clicando no link que acabamos de enviar.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Reenviar email de verificação',
          handler: () => {
            this.resendVerificationMail(this.email).subscribe((data) =>{
              this.toastService.success(data.msg);
            });
          }
        }
      ]
    });

    await alert.present();
  }

  resendVerificationMail(email: string): Observable<any> {
    const url = `${environment.apiURL}/resend/verification/mail`;
    /* eslint-disable @typescript-eslint/naming-convention */
    return this.http
      .post<IToken>(url, { email })
      .pipe(
        map((result) => result),
        catchError((error) => this.toastService.error(error.join('')))
      );
  }

  register(
    name: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ): Observable<any> {
    const url = `${environment.apiURL}/register`;
    /* eslint-disable @typescript-eslint/naming-convention */
    return this.http
      .post<IToken>(url, {
        name,
        email,
        password,
        password_confirmation: passwordConfirmation,
        device_token: localStorage.getItem('device_token')
      })
      .pipe( map((result) => result),
        catchError((res) => this.validError(res))
      );
  }

  delete(): Observable<any> {
    const me = JSON.parse(localStorage.getItem('me'));
    const url = `${environment.apiURL}/user/`+me.id;

    return this.http
      .delete<any>(url)
      .pipe( map((result) => result),
        catchError((res) => this.validError(res))
      );
  }


  recovery(email: string): Observable<any> {
    const url = `${environment.apiURL}/recovery`;
    /* eslint-disable @typescript-eslint/naming-convention */
    return this.http.post<any>(url, { email }).pipe(
      map((result) => result),
      catchError((res) => this.validError(res))
    );
  }

  me(): Observable<IMe> {
    this.initBearerToken();
    const url = `${environment.apiURL}/me`;
    /* eslint-disable @typescript-eslint/naming-convention */
    return this.http.get<any>(url,{headers:this.header}).pipe(
      map((result) => result)
    );
  }

  edit(formData: FormData){
    const url = `${environment.apiURL}/user/update`;
    return this.http.put<any>(url,formData,  { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.validError(error))
    );
  }

  private initBearerToken(){
    this.header = new HttpHeaders({
      authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }

  private validError(result){
    if ((result.status === 500) || (result.status === 406)) {
      this.toastService.error('Endereço de e-mail não pode ser verificado, por favor confira seus dados!');
    }
    if (result.status === 401) {
      this.toastService.warning(result.error[0]);
    }else {
        const email = result.error.errors.email ?? false;
        if(email){
          this.toastService.error(email.toString());
        }
    }



    return '';
  }
}
