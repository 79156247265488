import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from './../toast.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  header: HttpHeaders;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    public alertController: AlertController,
    private router: Router
  ) {
    this.initBearerToken();
  }

  get(search: string, page?: number): Observable<any> {
    this.initBearerToken();
    const url = `${environment.apiURL}/partner/list?search=${search}&page=${page}`;

    return this.http.get<any>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  save(formData: FormData){
    this.initBearerToken();
    const url = `${environment.apiURL}/partner/save`;

    return this.http.post<any>(url,formData,  { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  enable(id: number): Observable<any> {
    const url = `${environment.apiURL}/partner/enable/${id}`;

    return this.http.put<any>(url, null, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  disable(id: number): Observable<any> {
    const url = `${environment.apiURL}/partner/disable/${id}`;

    return this.http.delete<any>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  show(id: number): Observable<any> {
    const url = `${environment.apiURL}/partner/show/${id}`;

    return this.http.get<any>(url, { headers: this.header }).pipe(
      map((result) => result),
      catchError((error) => this.errorToast(error))
    );
  }

  async errorToast(res: any) {
    if ((res.status >= 401) && (res.status <= 415)) {
      this.toastService.error(res.error);
    }else {
      let message = '';
      let count = 1;
      for (const [key, value] of Object.entries(res.error.errors)) {
        message += count+') '+value+ '<br> ';
        count++;
      }

      if(count > 2){
        this.toastService.error('Os seguintes erros foram encontrados:<br>'+message);
      }else{
        this.toastService.error('O seguinte erro foi encontrado:<br>'+message);
      }

    }
    return false;
  }

  private initBearerToken(){
    this.header = new HttpHeaders({
      authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }

}
